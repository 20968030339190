import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route, Switch } from 'react-router-dom';

//frontend
const Home = lazy(() => import('./components/frontend/Home'));
const About = lazy(() => import('./components/frontend/About'));
const Loan = lazy(() => import('./components/frontend/Loan'));
const Insurance = lazy(() => import('./components/frontend/Insurance'));
const Dealers = lazy(() => import('./components/frontend/Dealers'));
const Contact = lazy(() => import('./components/frontend/Contact'));
const InquiryForm = lazy(() => import('./components/application/InquiryForm'));
const SearchCustomers = lazy(() => import('./components/customers/Search')); 
const PrintPreview = lazy(() => import('./components/admin/Inquiry/PrintPreview'));

//Backend
const Dashboard = lazy(() => import('./components/admin/Dashboard'));
const DealerLogin = lazy(() => import('./components/dealerLogin/Login')); 

function App() {
  return (
    <Router>
    <Suspense fallback={<div>Loading...</div>}>
      <React.Fragment>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/loan" element={<Loan />} />
          <Route exact path="/insurance" element={<Insurance />} />
          <Route exact path="/dealers" element={<Dealers />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/applyHP" element={<InquiryForm />} />
          <Route exact path="/applyLoan" element={<InquiryForm />} />
          <Route exact path="/checkCustomerRepayment" element={<SearchCustomers />} /> 
          <Route exact path="/financeSystem/printPreview" element={<PrintPreview />} /> 
          <Route exact path="/dealerLogin" element={<DealerLogin />} /> 
          <Route exact path="/financeSystem" element={<Dashboard />} />
        </Routes>
      </React.Fragment>
    </Suspense>
  </Router>
  );
}

export default App;
